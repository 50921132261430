import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import Unauthorized from "../Components/Unauthorized";

function Users() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.allRides) {
          for (
            var i = 0;
            i < response?.data?.registeredDrivers?.data?.length;
            i++
          ) {
            const decrypted = CryptoJS.AES.decrypt(
              response?.data?.registeredDrivers?.data[i]?.password,
              process.env.REACT_APP_JWT_SECRET
            ).toString(CryptoJS.enc.Utf8);
            response.data.registeredDrivers.data[i].password = decrypted;
          }
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);

  const updateUser = (user) => {
    setLoading(true);
    var updateData = user;
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/admin/updateUser`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken,
      },
      data: updateData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success === false) {
          toast.warn("Error!! Please try again later.");
        } else {
          toast.success("User info is updated.");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="max-w-7xl mx-auto px-6 p-24">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users signed up with Orienty Black Car Service.
            </p>
          </div>
        </div>
        {userDetails?.role === "ADMIN" ? (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Phone
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {dashboardData?.registeredUsers?.data?.map((person) => (
                        <tr key={person?.createdDate}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {person?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person?.phone}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person?.email}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person?.role}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <select
                              id="location"
                              name="location"
                              value={person?.active}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                              onChange={(e) => {
                                var updatedItems =
                                  dashboardData?.registeredUsers?.data?.map(
                                    (item) => {
                                      if (item.email === person?.email) {
                                        return {
                                          ...item,
                                          active:
                                            e.target.value.toLowerCase() ===
                                            "true",
                                        }; // Update the name
                                      }
                                      return item;
                                    }
                                  );
                                setDashboardData({
                                  ...dashboardData,
                                  registeredUsers: {
                                    ...dashboardData?.registeredUsers,
                                    data: updatedItems,
                                  },
                                });

                                var updateUserData = updatedItems?.filter(
                                  (item) => item?.email === person?.email
                                );
                                updateUser(updateUserData[0]);
                              }}
                            >
                              <option value={true}>Active</option>
                              <option value={false}>In-Active</option>
                            </select>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Unauthorized />
        )}
      </div>
    </>
  );
}

export default Users;
