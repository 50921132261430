import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogPanel } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/20/solid";
import { XMarkIcon } from "@heroicons/react/24/outline";
function Header() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  useEffect(() => {
    // Check for value in localStorage on component mount
    const storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    } else {
      navigate("/");
    }
  }, []);
  return (
    <header className="absolute inset-x-0 top-0 z-50 flex h-16 border-b border-gray-900/10">
      <div className="mx-auto flex w-full max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex flex-1 items-center gap-x-6">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-3 p-3 md:hidden"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="size-5 text-gray-900" />
          </button>
          <img
            alt="Orienty Black Car Service"
            src="https://res.cloudinary.com/dcfotquxt/image/upload/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
            className="h-10 w-auto"
          />
        </div>
        <nav className="hidden md:flex md:gap-x-11 md:text-sm/6 md:font-semibold md:text-gray-700">
          <a href="/dashboard">Dashboard</a>
          {userDetails?.role === "ADMIN" ? (
            <>
              <a href="/users">Users</a>
              <a href="/vehicles">Vehicles</a>
              <a href="/drivers">Drivers</a>
              <a href="/dispatchers">Dispatchers</a>
              <a href="/rides">Rides</a>
            </>
          ) : userDetails?.role === "DISPATCHER" ? (
            <>
              <a href="/vehicles">Vehicles</a>
              <a href="/drivers">Drivers</a>
              <a href="/rides">Rides</a>
            </>
          ) : (
            <>
              <a href="/rides">Rides</a>
            </>
          )}

          <a
            href="/"
            onClick={() => {
              localStorage.removeItem("__AdminOrientyBlackCarService__");
            }}
          >
            Sign Out
          </a>
        </nav>
        <div className="flex flex-1 items-center justify-end gap-x-8">
          <a href="/profile" className="-m-1.5 p-1.5">
            <span className="sr-only">Your profile</span>
            <img
              alt="User Image"
              src={userDetails?.img}
              className="size-8 rounded-full bg-gray-800"
            />
          </a>
        </div>
      </div>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-50" />
        <DialogPanel className="fixed inset-y-0 left-0 z-50 w-full overflow-y-auto bg-white px-4 pb-6 sm:max-w-sm sm:px-6 sm:ring-1 sm:ring-gray-900/10">
          <div className="-ml-0.5 flex h-16 items-center gap-x-6">
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="size-6" />
            </button>
            <div className="-ml-0.5">
              <a
                href="#"
                className="-m-1.5 block p-1.5"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 15,
                }}
              >
                <span className="sr-only">OrientyBlackCarService</span>
                <img
                  alt="Orienty Black Car Service"
                  src="https://res.cloudinary.com/dcfotquxt/image/upload/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
                  className="h-10 w-auto"
                />
                <p
                  className="m-0"
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  OrientyBlackCarService
                </p>
              </a>
            </div>
          </div>
          <div className="mt-2 space-y-2">
            <a
              href="/dashboard"
              className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
            >
              Dashboard
            </a>
            {userDetails?.role === "ADMIN" ? (
              <>
                <a
                  href="/users"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Users
                </a>
                <a
                  href="/vehicles"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Vehicles
                </a>
                <a
                  href="/drivers"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Drivers
                </a>
                <a
                  href="/dispatchers"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Dispatchers
                </a>
                <a
                  href="/rides"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Rides
                </a>
              </>
            ) : userDetails?.role === "DISPATCHER" ? (
              <>
                <a
                  href="/vehicles"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Vehicles
                </a>
                <a
                  href="/drivers"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Drivers
                </a>
                <a
                  href="/rides"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Rides
                </a>
              </>
            ) : (
              <>
                <a
                  href="/rides"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Rides
                </a>
              </>
            )}

            <a
              className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
              onClick={() => {
                localStorage.removeItem("__AdminOrientyBlackCarService__");
                navigate("/");
              }}
            >
              Sign Out
            </a>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}

export default Header;
