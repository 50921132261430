import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import moment from "moment";
import Unauthorized from "../Components/Unauthorized";

import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

function Vehicles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [selected, setSelected] = useState({
    specifications: [
      {
        value: "0",
      },
      {
        value: "0",
      },
    ],
  });

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.allRides) {
          setSelected(response?.data?.vehiclesFleet?.data[0]);
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);
  const handleAddVehChange = (e) => {
    const { name, value } = e.target;
    setSelected((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const deleteVehicle = (vehId) => {
    let userResponse = window?.confirm(
      "Do you want to delete this item? This can't be undone."
    );

    if (userResponse) {
      setLoading(true);

      let config = {
        method: "delete",
        url: `${baseUrl}/v1/admin/vehicle`,
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails?.authToken,
        },
        data: {
          vehId,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success === false) {
            toast.warn("Error!! Please try again later.");
          } else {
            toast.success("Success!! Vehicle deleted.");
            setTimeout(() => {
              window.location.reload();
            }, 3000);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error!! Please try again later.");
        });
    }
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Dialog
        open={open}
        onClose={setOpen}
        className="relative z-10"
        style={{
          zIndex: 999,
        }}
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-base font-semibold text-gray-900"
                      >
                        Vehicle
                      </h2>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-4">
                      <div>
                        <div className="relative h-40 sm:h-56">
                          <img
                            alt=""
                            src={selected?.img}
                            className="absolute size-full object-cover"
                          />
                        </div>
                        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                          <div className="sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                  {selected?.name}
                                </h3>
                                <span className="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                                  <span className="sr-only">Online</span>
                                </span>
                              </div>
                              <p className="text-sm text-gray-500">
                                {selected?.cat}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                        <div>
                          <label
                            htmlFor="comment"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Description
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="descriptions"
                              name="descriptions"
                              rows={3}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              value={selected?.descriptions}
                              onChange={handleAddVehChange}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Vehicle price per mile (USD)
                          </label>
                          <div className="mt-2">
                            <input
                              id="email"
                              type="number"
                              name="perMilePrice"
                              value={selected?.perMilePrice}
                              onChange={handleAddVehChange}
                              placeholder="3"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Vehicle price per hour (USD)
                          </label>
                          <div className="mt-2">
                            <input
                              id="perHourPrice"
                              type="number"
                              name="perHourPrice"
                              value={selected?.perHourPrice}
                              onChange={handleAddVehChange}
                              placeholder="0"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Vehicle features:
                          </label>
                          <div className="mt-2">
                            <input
                              id="features"
                              type="text"
                              name="features"
                              value={selected?.features?.join(",")}
                              onChange={(e) => {
                                setSelected((prevData) => ({
                                  ...prevData,
                                  features: e?.target?.value?.split(","),
                                }));
                              }}
                              placeholder="Bluetooth,Multimedia"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>

                        <div className="mt-5 flex flex-wrap space-y-2 sm:space-x-2 sm:space-y-0">
                          <div className="flex-1">
                            <label className="block text-sm/6 font-medium text-gray-900">
                              Seats
                            </label>
                            <div className="mt-2">
                              <input
                                id="seats"
                                type="text"
                                name="seats"
                                value={selected?.specifications[0]?.value}
                                onChange={(e) => {
                                  selected.specifications[0].value =
                                    e?.target?.value;
                                  setSelected((prevData) => ({
                                    ...prevData,
                                    specifications: selected?.specifications,
                                  }));
                                }}
                                placeholder="3"
                                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              />
                            </div>
                          </div>

                          <div className="flex-1">
                            <label className="block text-sm/6 font-medium text-gray-900">
                              Baggage
                            </label>
                            <div className="mt-2">
                              <input
                                id="baggage"
                                type="text"
                                name="baggage"
                                value={selected?.specifications[1]?.value}
                                onChange={(e) => {
                                  selected.specifications[1].value =
                                    e?.target?.value;
                                  setSelected((prevData) => ({
                                    ...prevData,
                                    specifications: selected?.specifications,
                                  }));
                                }}
                                placeholder="3"
                                className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          <button
                            type="button"
                            className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                            onClick={() => {
                              delete selected?._id;
                              setLoading(true);

                              var config = {
                                method: "patch",
                                url: `${baseUrl}/v1/admin/vehicle`,
                                headers: {
                                  "Content-Type": "application/json",
                                  Authorization: userDetails?.authToken,
                                },
                                data: selected,
                              };
                              axios
                                .request(config)
                                .then((response) => {
                                  setLoading(false);
                                  if (response?.data?.success) {
                                    toast.success(
                                      "Success!! Vehicle info is updated."
                                    );
                                    setOpen(false);
                                    setTimeout(() => {
                                      window.location.reload();
                                    }, 1000);
                                  }
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  setOpen(false);
                                  toast.warn("Error!! Please try again later.");
                                });
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto px-6 p-24">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">Vehicles</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the vehicle fleet.
            </p>
          </div>
          {/* <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add Vehile
            </button>
          </div> */}
        </div>
        {userDetails?.role === "ADMIN" || userDetails?.role === "DISPATCHER" ? (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Id
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Price per mile
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Price per hour
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Created on
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {dashboardData?.vehiclesFleet?.data?.map((person, i) => (
                        <tr key={person?.createdDate}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                            {person?.vehId}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person?.name}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            USD ${person?.perMilePrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            USD ${person?.perHourPrice}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {moment
                              .unix(person?.createdDate)
                              .format("MMM DD,YYYY")}
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div
                              className="text-indigo-600 hover:text-indigo-900"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelected(person);
                                setOpen(true);
                              }}
                            >
                              Edit
                            </div>
                            {userDetails?.role === "ADMIN" ? (
                              <div
                                className="text-red-600 hover:text-red-900"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  const fleetData =
                                    dashboardData?.vehiclesFleet?.data.filter(
                                      (_, index) => index === i
                                    );
                                  const remFleetData =
                                    dashboardData?.vehiclesFleet?.data.filter(
                                      (_, index) => index !== i
                                    );

                                  deleteVehicle(
                                    fleetData[0]?.vehId,
                                    remFleetData
                                  );
                                }}
                              >
                                Delete
                              </div>
                            ) : null}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Unauthorized />
        )}
      </div>
    </>
  );
}

export default Vehicles;
