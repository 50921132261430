import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import CryptoJS from "crypto-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";

function Profile() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Profile Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/userProfile`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.success) {
          const decrypted = CryptoJS.AES.decrypt(
            response?.data?.password,
            process.env.REACT_APP_JWT_SECRET
          ).toString(CryptoJS.enc.Utf8);
          setUserDetails({ ...response?.data, password: decrypted });
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);

  const updateData = () => {
    if (
      !userDetails?.name ||
      !userDetails?.email ||
      !userDetails?.contact ||
      !userDetails?.password
    ) {
      toast.warn("Please fill all the details.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userDetails?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      var updateData = {
        name: userDetails?.name,
        email: userDetails?.email,
        contact: userDetails?.contact,
        password: encrypted,
        img: userDetails?.img,
      };

      let config = {
        method: "patch",
        url: `${baseUrl}/v1/userProfile`,
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails?.authToken,
        },
        data: updateData,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success === false) {
            toast.warn("Error!! Please try again later.");
          } else {
            localStorage.setItem(
              "__AdminOrientyBlackCarService__",
              JSON.stringify(response?.data)
            );
            toast.success("Profile info is updated.");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error!! Please try again later.");
        });
    }
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <div className="max-w-7xl mx-auto px-6 p-24">
        <div className="space-y-12 sm:space-y-16">
          <div>
            <h2 className="text-base/7 font-semibold text-gray-900">Profile</h2>
            <p className="mt-1 max-w-2xl text-sm/6 text-gray-600">
              User information details
            </p>

            <div className="mt-10 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Name
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    placeholder="Enter name"
                    value={userDetails?.name}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        name: e?.target?.value,
                        img: `https://api.dicebear.com/9.x/initials/svg?seed=${e?.target?.value}`,
                      });
                    }}
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm/6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Email
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    placeholder="Enter email"
                    value={userDetails?.email}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        email: e?.target?.value,
                      });
                    }}
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm/6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Phone
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="text"
                    placeholder="Enter Phone Contact"
                    value={userDetails?.phone}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        phone: e?.target?.value,
                      });
                    }}
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm/6"
                  />
                </div>
              </div>
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-6">
                <label
                  htmlFor="street-address"
                  className="block text-sm/6 font-medium text-gray-900 sm:pt-1.5"
                >
                  Password
                </label>
                <div className="mt-2 sm:col-span-2 sm:mt-0">
                  <input
                    type="password"
                    placeholder="Enter Password"
                    value={userDetails?.password}
                    onChange={(e) => {
                      setUserDetails({
                        ...userDetails,
                        password: e?.target?.value,
                      });
                    }}
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xl sm:text-sm/6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm/6 font-semibold text-gray-900"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center rounded-md bg-themeColor px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-themeColor focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-themeColor"
            onClick={updateData}
          >
            Save
          </button>
        </div>
      </div>
    </>
  );
}

export default Profile;
