import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Pages/Profile";
import Users from "./Pages/Users";
import Vehicles from "./Pages/Vehicles";
import Drivers from "./Pages/Drivers";
import Dispatchers from "./Pages/Dispatchers";
import Rides from "./Pages/Rides";
import NotFound from "./Pages/404";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/users" element={<Users />} />
        <Route path="/vehicles" element={<Vehicles />} />
        <Route path="/drivers" element={<Drivers />} />
        <Route path="/dispatchers" element={<Dispatchers />} />
        <Route path="/rides" element={<Rides />} />
        <Route path=":noPath" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
