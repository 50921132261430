"use client";

import { useState, Fragment, useEffect } from "react";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import moment from "moment";

function Dashboard() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.allRides) {
          for (
            var i = 0;
            i < response?.data?.registeredDrivers?.data?.length;
            i++
          ) {
            const decrypted = CryptoJS.AES.decrypt(
              response?.data?.registeredDrivers?.data[i]?.password,
              process.env.REACT_APP_JWT_SECRET
            ).toString(CryptoJS.enc.Utf8);
            response.data.registeredDrivers.data[i].password = decrypted;
          }
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <main className="pt-16">
        <div className="relative isolate overflow-hidden">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base/7 font-semibold text-gray-900">
                Dashboard
              </h1>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Vehicles
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.vehiclesFleet?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Registered Users
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.registeredUsers?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">Drivers</dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.registeredDrivers?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  All Rides
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.allRides?.totalCount || "--"}
                </dd>
              </div>
            </dl>
          </div>

          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              <div className="flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Scheduled Rides
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.scheduledRides?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Confirmed Rides
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.confirmedRides?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Completed Rides
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.completedRides?.totalCount || "--"}
                </dd>
              </div>
              <div className="lg:border-l sm:border-l flex flex-wrap items-baseline justify-between gap-x-4 gap-y-2 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8">
                <dt className="text-sm/6 font-medium text-gray-500">
                  Cancelled Rides
                </dt>

                <dd className="w-full flex-none text-3xl/10 font-medium tracking-tight text-gray-900">
                  {dashboardData?.cancelledRides?.totalCount || "--"}
                </dd>
              </div>
            </dl>
          </div>

          <div
            aria-hidden="true"
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          >
            <div
              style={{
                clipPath:
                  "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
              }}
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
            />
          </div>
        </div>

        <div className="space-y-16 py-16 xl:space-y-20">
          {/* Recent activity table */}
          <div>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex items-center justify-between">
              <h2 className="mx-auto max-w-2xl text-base font-semibold text-gray-900 lg:mx-0 lg:max-w-none">
                Recent Rides
              </h2>
              <a
                href="/rides"
                className="text-sm/6 font-semibold text-indigo-600 hover:text-indigo-500"
              >
                View all
              </a>
            </div>
            <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                  <table className="w-full text-left">
                    <thead className="sr-only">
                      <tr>
                        <th>Amount</th>
                        <th className="hidden sm:table-cell">Client</th>
                        <th>More details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashboardData?.allRides?.data
                        ?.slice(0, 3)
                        ?.map((day) => (
                          <Fragment key={day?.createdDate}>
                            <tr>
                              <td className="relative py-5 pr-6">
                                <div className="flex gap-x-6">
                                  <ArrowDownCircleIcon
                                    aria-hidden="true"
                                    className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                  />
                                  {userDetails?.role === "ADMIN" ? (
                                    <div className="flex-auto">
                                      <div className="flex items-start gap-x-3">
                                        <div className="text-sm/6 font-medium text-gray-900">
                                          USD $
                                          {day?.paymentIntent?.amount / 100}
                                        </div>
                                        <div className="text-green-700 bg-green-50 ring-green-600/20 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">
                                          Paid
                                        </div>
                                      </div>
                                      <div className="mt-1 text-xs/5 text-gray-500">
                                        {day?.orderNumber}
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="flex-auto">
                                      <div className="flex items-start gap-x-3">
                                        <div className="text-sm/6 font-medium text-gray-900">
                                          {day?.rideData?.pickUpAddress}
                                        </div>
                                      </div>
                                      <div className="mt-1 text-xs/5 text-gray-500">
                                        {day?.rideData?.dropOffAddress}
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                              </td>
                              <td className="hidden py-5 pr-6 sm:table-cell">
                                <div className="text-sm/6 text-gray-900">
                                  {day?.userDetails?.name}
                                </div>
                                <div className="mt-1 text-xs/5 text-gray-500">
                                  {day?.userDetails?.email}
                                </div>
                              </td>
                              <td className="py-5 text-right">
                                <div className="flex justify-end">
                                  <a
                                    href={day?.status}
                                    className="text-sm/6 font-medium text-indigo-600 hover:text-indigo-500"
                                  >
                                    {day?.status}
                                  </a>
                                </div>
                                <div className="mt-1 text-xs/5 text-gray-500">
                                  Invoice{" "}
                                  <span className="text-gray-900">
                                    #{day?.orderNumber}
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          {/* Recent client list*/}
          {userDetails?.role === "ADMIN" && (
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base/7 font-semibold text-gray-900">
                    Recent clients
                  </h2>
                  <a
                    href="/users"
                    className="text-sm/6 font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    View all<span className="sr-only">, clients</span>
                  </a>
                </div>
                <ul
                  role="list"
                  className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
                >
                  {dashboardData?.registeredUsers?.data
                    ?.slice(0, 3)
                    ?.map((client) => (
                      <li
                        key={client?.createdDate}
                        className="overflow-hidden rounded-xl border border-gray-200"
                      >
                        <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                          <img
                            alt={client?.name}
                            src={client?.img}
                            className="size-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                          />
                          <div className="text-sm/6 font-medium text-gray-900">
                            {client?.name}
                            <p className="text-gray-400">{client?.email}</p>
                          </div>
                        </div>
                        <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm/6">
                          <div className="flex justify-between gap-x-4 py-3">
                            <dt className="text-gray-500">Signed Up:</dt>
                            <dd className="text-gray-700">
                              <time dateTime={client?.createdDate}>
                                {moment
                                  .unix(client?.createdDate)
                                  .format("MMM DD,YYYY")}
                              </time>
                            </dd>
                          </div>
                        </dl>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
}
export default Dashboard;
