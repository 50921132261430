import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import moment from "moment";
import Unauthorized from "../Components/Unauthorized";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";

function Drivers() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [selected, setSelected] = useState({});

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.allRides) {
          for (
            var i = 0;
            i < response?.data?.registeredDrivers?.data?.length;
            i++
          ) {
            const decrypted = CryptoJS.AES.decrypt(
              response?.data?.registeredDrivers?.data[i]?.password,
              process.env.REACT_APP_JWT_SECRET
            ).toString(CryptoJS.enc.Utf8);

            response.data.registeredDrivers.data[i].password = decrypted;
          }
          setSelected(response?.data?.registeredDrivers?.data[0]);
          response.data.registeredDrivers.data =
            response?.data?.registeredDrivers?.data?.sort((a, b) => {
              return a.name.localeCompare(b.name);
            });
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);
  const handleAddVehChange = (e) => {
    const { name, value } = e.target;
    setSelected((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Dialog
        open={open}
        onClose={setOpen}
        className="relative z-10"
        style={{
          zIndex: 999,
        }}
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-base font-semibold text-gray-900"
                      >
                        {selected?.driverId ? "Edit Driver" : "Add Driver"}
                      </h2>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-4">
                      <div>
                        <div className="relative h-40 sm:h-56">
                          <img
                            alt=""
                            src={`https://api.dicebear.com/9.x/initials/svg?seed=${selected?.name}`}
                            className="absolute size-full object-cover"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-4 pb-5 pt-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:space-y-6 sm:px-6">
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Driver name
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              type="text"
                              name="name"
                              value={selected?.name}
                              onChange={handleAddVehChange}
                              placeholder="Driver name"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Phone
                          </label>
                          <div className="mt-2">
                            <input
                              id="phone"
                              type="text"
                              name="phone"
                              value={selected?.phone}
                              onChange={handleAddVehChange}
                              placeholder="Contact"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              id="email"
                              type="text"
                              name="email"
                              value={selected?.email}
                              onChange={handleAddVehChange}
                              placeholder="abc@abc.com"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Password
                          </label>
                          <div className="mt-2">
                            <input
                              id="password"
                              type="text"
                              name="password"
                              value={selected?.password}
                              onChange={handleAddVehChange}
                              placeholder="Password"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Vehicles
                          </label>
                          <div className="mt-2">
                            <input
                              id="vehicles"
                              type="text"
                              name="vehicles"
                              value={selected?.vehicles}
                              onChange={handleAddVehChange}
                              placeholder="Vehicles"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div>
                          <label className="block text-sm/6 font-medium text-gray-900">
                            Status
                          </label>
                          <select
                            value={selected?.active}
                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            onChange={(e) => {
                              setSelected((prevData) => ({
                                ...prevData,
                                active: e.target.value.toLowerCase() === "true",
                              }));
                            }}
                          >
                            <option value={true}>Active</option>
                            <option value={false}>In-Active</option>
                          </select>
                        </div>

                        <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          <button
                            type="button"
                            className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                          {selected?.driverId ? (
                            <button
                              type="button"
                              className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                              onClick={() => {
                                if (
                                  selected?.name &&
                                  selected?.email &&
                                  selected?.password
                                ) {
                                  const encrypted = CryptoJS.AES.encrypt(
                                    selected?.password,
                                    process.env.REACT_APP_JWT_SECRET
                                  ).toString();
                                  delete selected?._id;
                                  selected.img = `https://api.dicebear.com/9.x/initials/svg?seed=${selected?.name}`;
                                  setLoading(true);
                                  var config = {
                                    method: "patch",
                                    url: `${baseUrl}/v1/admin/driver`,
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: userDetails?.authToken,
                                    },
                                    data: { ...selected, password: encrypted },
                                  };
                                  axios
                                    .request(config)
                                    .then((response) => {
                                      setLoading(false);
                                      if (response?.data?.success) {
                                        toast.success(
                                          "Success!! Driver info is updated."
                                        );
                                        setOpen(false);
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      }
                                    })
                                    .catch((error) => {
                                      setLoading(false);
                                      toast.warn(
                                        "Error!! Please try again later."
                                      );
                                    });
                                } else {
                                  toast.warn("Error!! Missing information");
                                }
                              }}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                              onClick={() => {
                                if (
                                  selected?.name &&
                                  selected?.email &&
                                  selected?.password
                                ) {
                                  const encrypted = CryptoJS.AES.encrypt(
                                    selected?.password,
                                    process.env.REACT_APP_JWT_SECRET
                                  ).toString();
                                  selected.img = `https://api.dicebear.com/9.x/initials/svg?seed=${selected?.name}`;
                                  setLoading(true);
                                  var config = {
                                    method: "post",
                                    url: `${baseUrl}/v1/admin/driver`,
                                    headers: {
                                      "Content-Type": "application/json",
                                      Authorization: userDetails?.authToken,
                                    },
                                    data: {
                                      ...selected,
                                      password: encrypted,
                                    },
                                  };
                                  axios
                                    .request(config)
                                    .then((response) => {
                                      setLoading(false);
                                      if (response?.data?.success) {
                                        toast.success(
                                          "Success!! Driver added to fleet."
                                        );
                                        setOpen(false);
                                        setTimeout(() => {
                                          window.location.reload();
                                        }, 1000);
                                      }
                                    })
                                    .catch((error) => {
                                      setLoading(false);
                                      toast.warn(
                                        "Error!! Please try again later."
                                      );
                                    });
                                } else {
                                  toast.warn("Error!! Missing information");
                                }
                              }}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </dl>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto px-6 p-24">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">Drivers</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the drivers.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={() => {
                setSelected({});
                setOpen(true);
              }}
            >
              Add Driver
            </button>
          </div>
        </div>
        {userDetails?.role === "ADMIN" || userDetails?.role === "DISPATCHER" ? (
          <div className="mt-8 flow-root">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Id
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Vehicles
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Contact
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {dashboardData?.registeredDrivers?.data?.map(
                        (person, i) => (
                          <tr key={person?.createdDate}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {person?.driverId}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.name}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.vehicles}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.phone}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {person?.active ? "Active" : "In-Active"}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <div
                                className="text-indigo-600 hover:text-indigo-900"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setSelected(person);
                                  setOpen(true);
                                }}
                              >
                                Edit
                              </div>
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Unauthorized />
        )}
      </div>
    </>
  );
}

export default Drivers;
