import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Loading";
import CryptoJS from "crypto-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";

function Login() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const signInUser = (e) => {
    if (!userData?.email || !userData.password) {
      toast.warn("Error! Please fill all the fields.");
    } else {
      setLoading(true);
      const encrypted = CryptoJS.AES.encrypt(
        userData?.password,
        process.env.REACT_APP_JWT_SECRET
      ).toString();
      let data = JSON.stringify({ ...userData, password: encrypted });

      let config = {
        method: "post",
        url: `${baseUrl}/v1/signIn`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          setLoading(false);
          if (response?.data?.success) {
            if (
              response?.data?.role === "ADMIN" ||
              response?.data?.role === "DRIVER" ||
              response?.data?.role === "DISPATCHER"
            ) {
              localStorage.setItem(
                "__AdminOrientyBlackCarService__",
                JSON.stringify(response?.data)
              );
              navigate("/dashboard");
            } else {
              toast.warn("Email & Password mismatch.");
            }
          } else {
            toast.warn("Email & Password mismatch.");
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.warn("Error! Please try again later.");
        });
    }
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div
        className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
        style={{
          height: "100vh",
          backgroundImage:
            "url(https://orientyblackcarservice.com/images/background/1.jpg)",
        }}
      >
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            alt="Orienty Black Car Service"
            src="https://res.cloudinary.com/dcfotquxt/image/upload/e_colorize:100,co_white/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"
            className="mx-auto h-20 w-auto"
          />
          <h2 className="mt-6 text-center text-2xl/9 font-bold tracking-tight text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <div className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Email address
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    value={userData.email}
                    onChange={handleChange}
                    required
                    autoComplete="email"
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm/6 font-medium text-gray-900"
                >
                  Password
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={userData.password}
                    onChange={handleChange}
                    required
                    autoComplete="current-password"
                    className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm/6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  onClick={signInUser}
                >
                  Sign in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
