import React from "react";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { ArrowDownCircleIcon, MapPinIcon } from "@heroicons/react/20/solid";
import Header from "../Components/Header";
import Loading from "../Components/Loading";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import baseUrl from "../Helper/config";
import moment from "moment";
import { Dialog, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";
import { DialogBackdrop } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import jsPDF from "jspdf";
import "jspdf-autotable";

const libraries = ["places"];

function Rides() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [dashboardData, setDashboardData] = useState({});
  const [selected, setSelected] = useState({});

  const dateInputRef = useRef(null);
  const handleClick = () => {
    dateInputRef.current.showPicker();
  };
  const today = new Date();
  const options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = today.toLocaleDateString("en-US", options);
  const [showDate, setShowDate] = useState(today.toLocaleDateString("en-CA"));
  const [pickUpDate, setPickUpDate] = useState(formattedDate);

  const [pickUpAddressAllData, setPickUpAddressAllData] = useState({});
  const [pickUpAddress, setPickUpAddress] = useState("");
  const searchBoxPickUpRef = useRef(null);
  const handlePickUpPlaceChanged = () => {
    const place = searchBoxPickUpRef.current.getPlaces()[0];
    setPickUpAddressAllData(place);
    if (place) {
      setPickUpAddress(place.formatted_address || place.name);
    }
  };
  const [dropOffAddressAllData, setDropOffAddressAllData] = useState({});
  const [dropOffAddress, setDropOffAddress] = useState("");
  const searchBoxDropOffRef = useRef(null);
  const handleDropOffPlaceChanged = () => {
    const place = searchBoxDropOffRef.current.getPlaces()[0];
    setDropOffAddressAllData(place);
    if (place) {
      setDropOffAddress(place.formatted_address || place.name);
    }
  };
  const [newRide, setNewRide] = useState({
    carData: {
      _id: "6723d7e7c9b29eaab8caa532",
      img: "https://res.cloudinary.com/dcfotquxt/image/upload/h_608/v1730936001/Orienty%20Black%20Car%20Service/Vehicles/2020-Cadillac-Escalade-in-black_yj5dmd.webp",
      name: "SUV",
      cat: "SUV",
      descriptions:
        "The first generation of the M2 is the F87 coupé and is powered by turbocharged.",
      specifications: [
        {
          name: "Seats",
          value: "6",
        },
        {
          name: "Baggage",
          value: "6",
        },
      ],
      features: ["Bluetooth", "Multimedia Player", "Central Lock", "Moonroof"],
      createdDate: 1730402278,
      vehId: 1730402278,
      lastUpdated: 1730914225,
      perMilePrice: 4,
    },
    userDetails: {
      name: "",
      email: "",
      phone: "",
    },
    rideData: {
      pickUpAddress: pickUpAddress,
      dropOffAddress: dropOffAddress,
      pickUpDate: "",
      pickUpTime: "00:00 AM",
    },
    taxCharged: "",
    additionalDetails: {
      numberOfPassengers: "0",
      numberOfBaggage: "0",
      additionalDetailsText: "",
      flightNumber: "",
    },
    additionalSeatsDetails: {
      boosterSeat: "0",
      childSeat: "0",
      infantSeat: "0",
    },
  });

  useEffect(() => {
    setLoading(true);
    var storedUsername = JSON.parse(
      localStorage.getItem("__AdminOrientyBlackCarService__")
    );
    if (storedUsername) {
      setUserDetails(storedUsername);
    }

    // Dashboard Data
    var config = {
      method: "post",
      url: `${baseUrl}/v1/dashboardData`,
      headers: {
        "Content-Type": "application/json",
        Authorization: storedUsername?.authToken,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.tokenExpired) {
          localStorage.removeItem("__AdminOrientyBlackCarService__");
          navigate("/");
        }
        if (response?.data?.allRides) {
          setSelected(response?.data?.allRides?.data[0]);
          setDashboardData(response?.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  }, []);

  const updateRide = (ride) => {
    setLoading(true);
    delete ride?._id;
    var updateData = ride;
    let config = {
      method: "patch",
      url: `${baseUrl}/v1/admin/updateRide`,
      headers: {
        "Content-Type": "application/json",
        Authorization: userDetails?.authToken,
      },
      data: updateData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response?.data?.success === false) {
          toast.warn("Error!! Please try again later.");
        } else {
          toast.success("Ride status is updated.");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.warn("Error!! Please try again later.");
      });
  };
  const [additionalDetailsText, setAdditionalDetailsText] = useState("");

  const generateInvoice = (ride) => {
    const doc = new jsPDF();

    // Company Name and Logo
    const companyLogo =
      "https://res.cloudinary.com/dcfotquxt/image/upload/v1726066341/Orienty%20Black%20Car%20Service/Logos/transparent-file-thumb_gk3aze.png"; // Replace with actual logo URL
    doc.addImage(companyLogo, "PNG", 10, 10, 25, 20);
    doc.setFontSize(12);
    doc.text("ORIENTY BLACK CAR SERVICE", 45, 20);

    // Invoice Number and Date
    doc.setFontSize(10);
    doc.text(`Invoice No: ${ride?.orderNumber}`, 150, 20);
    doc.text(
      `Date: ${moment.unix(ride?.createdDate).format("MMM DD,YYYY")}`,
      150,
      25
    );

    // Customer Details, Bill To, and Payment Details
    doc.setFontSize(10);
    doc.text("Bill To:", 10, 50);
    doc.text(`Name: ${ride?.userDetails?.name}`, 10, 55);
    doc.text(`Email: ${ride?.userDetails?.email}`, 10, 60);
    doc.text(`Phone: ${ride?.userDetails?.contact}`, 10, 65);

    doc.text("Payment Details:", 150, 50);
    doc.text(`Payment Method: CARD`, 150, 55);

    // Table Header and Content
    const tableColumns = ["Description", "Vehicle", "Ride Type", "Amount"];
    const tableRows = [
      [
        `${ride?.rideData?.pickUpAddress}\n${
          ride?.rideData?.rideType
            ? ride?.rideData?.rideType?.title === "Hourly"
              ? `Booking Hours: ${ride?.rideData?.rideHours}`
              : `to\n${ride?.rideData?.dropOffAddress}`
            : `to\n${ride?.rideData?.dropOffAddress}`
        }`,
        `${ride?.carData?.name}`,
        `${
          ride?.rideData?.rideType
            ? ride?.rideData?.rideType?.title
            : "Point to point"
        }`,
        `${
          isNaN(
            Number(Number(ride?.amount / 100) - ride?.taxCharged).toFixed(2)
          )
            ? `Internally Created`
            : `${Number(Number(ride?.amount / 100) - ride?.taxCharged).toFixed(
                2
              )}`
        }`,
      ],
    ];

    doc.autoTable({
      startY: 75,
      margin: { left: 10 },
      head: [tableColumns],
      body: tableRows,
      theme: "grid",
      styles: { fontSize: 10, halign: "center" },
      headStyles: { fillColor: [0, 0, 0], textColor: [255, 255, 255] },
    });

    if (
      !isNaN(Number(Number(ride?.amount / 100) - ride?.taxCharged).toFixed(2))
    ) {
      // Subtotal, Tax, and Total Rows
      let finalY = doc.lastAutoTable.finalY || 75;
      doc.text("Subtotal:", 155, finalY + 10);
      doc.text(
        `${Number(ride?.amount / 100 - ride?.taxCharged).toFixed(2)}`,
        185,
        finalY + 10,
        { align: "right" }
      );

      doc.text("Tax:", 155, finalY + 15);
      doc.text(`${Number(ride?.taxCharged).toFixed(2)}`, 185, finalY + 15, {
        align: "right",
      });

      doc.setFontSize(12);
      doc.text("Total:", 155, finalY + 25);
      doc.text(`${Number(ride?.amount / 100).toFixed(2)}`, 185, finalY + 25, {
        align: "right",
      });
    }

    // Footer Message
    doc.setFontSize(10);
    doc.text("Thanks for choosing OrientyBlackCarService", 10, 280);

    // Save the PDF
    doc.save(`OrientyBlackCarService-${ride?.orderNumber}.pdf`);
  };
  return (
    <>
      {loading && <Loading />}
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Header />
      <Dialog
        open={open}
        onClose={setOpen}
        className="relative z-10"
        style={{
          zIndex: 999,
        }}
      >
        <div className="fixed inset-0 bg-black bg-opacity-50" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <DialogPanel
                transition
                className="pointer-events-auto w-screen max-w-md transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700"
              >
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-base font-semibold text-gray-900"
                      >
                        {selected?.orderNumber ? "Ride Details" : "Add Ride"}
                      </h2>
                      <div className="ml-3 flex h-7 items-center">
                        <button
                          type="button"
                          onClick={() => setOpen(false)}
                          className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                        >
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon aria-hidden="true" className="size-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-4">
                      <div>
                        <div className="relative h-40 sm:h-56">
                          <iframe
                            src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI&origin=${selected?.rideData?.pickUpAddress}&destination=${selected?.rideData?.dropOffAddress}&mode=driving`}
                            style={{
                              border: 0,
                            }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            className="absolute size-full object-cover"
                          ></iframe>
                        </div>
                        <p className="m-4 mb-2 border-2 border-dashed text-md font-bold text-gray-900 text-center ">
                          {selected?.status?.toUpperCase()}
                        </p>
                        {userDetails?.role === "ADMIN" ? (
                          <p className="m-4 mb-2 border-2 border-dashed text-md font-bold text-gray-900 text-center ">
                            Paid:&nbsp;$
                            {Number(selected?.amount / 100)}&nbsp;USD
                          </p>
                        ) : null}
                        <div className=" px-4 sm:flex sm:items-end sm:px-6">
                          <div className="sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">
                                  {selected?.userDetails?.name}
                                </h3>
                              </div>
                              <p className="text-sm text-gray-500">
                                {selected?.userDetails?.email}
                              </p>
                              <p className="text-sm text-gray-500">
                                {selected?.userDetails?.phone}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <nav aria-label="Progress" className="px-4">
                      <ol role="list" className="overflow-hidden relative">
                        <li className="pb-6">
                          <>
                            <div
                              aria-hidden="true"
                              className="absolute left-4 top-0 -ml-px mt-0.5 h-full w-0.5 bg-indigo-600"
                            />
                            <a
                              href="#"
                              className="group relative flex items-start"
                            >
                              <span className="flex h-9 items-center">
                                <span
                                  className="relative z-10 flex size-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800"
                                  style={{
                                    marginTop: "-5px",
                                  }}
                                >
                                  <MapPinIcon
                                    aria-hidden="true"
                                    className="size-5 text-white"
                                  />
                                </span>
                              </span>
                              <span className="ml-4 flex flex-wrap min-w-0 w-full flex-col">
                                <span className="text-sm font-medium">
                                  {selected?.rideData?.pickUpAddress}
                                </span>

                                <span className="text-sm text-gray-500">
                                  Pick-up Address
                                </span>
                              </span>
                            </a>
                          </>
                        </li>
                        <li className="relative">
                          <>
                            <a
                              href="#"
                              className="group relative flex items-start"
                            >
                              <span className="flex h-9 items-center">
                                <span className="relative z-10 flex size-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
                                  <MapPinIcon
                                    aria-hidden="true"
                                    className="size-5 text-white"
                                  />
                                </span>
                              </span>
                              <span className="ml-4 flex min-w-0 w-full flex-col">
                                <span className="text-sm font-medium">
                                  {selected?.rideData?.dropOffAddress}
                                </span>

                                <span className="text-sm text-gray-500">
                                  Drop-off Address
                                </span>
                              </span>
                            </a>
                          </>
                        </li>
                      </ol>
                    </nav>

                    {userDetails?.role === "ADMIN" ||
                    userDetails?.role === "DISPATCHER" ? (
                      <>
                        <div className="mt-5 mb-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0 px-4">
                          <div className="w-full flex-1">
                            <label
                              htmlFor="email"
                              className="block text-sm/6 font-medium text-gray-900"
                            >
                              Pick-up date
                            </label>
                            <div className="mt-2">
                              <input
                                type="date"
                                name="Pick Up Date"
                                value={showDate}
                                style={{
                                  fontWeight: 400,
                                  lineHeight: 1.6,
                                  border: "1px solid #031b4e80",
                                  borderRadius: "3px",
                                  height: "40px",
                                  width: "100%",
                                  paddingLeft: 12,
                                  color: "#4f4f4f",
                                }}
                                ref={dateInputRef}
                                onClick={handleClick}
                                onChange={(e) => {
                                  const [year, month, day] =
                                    e.target.value.split("-");
                                  const date = new Date(year, month - 1, day);
                                  const formattedDate = date.toLocaleDateString(
                                    "en-US",
                                    {
                                      year: "numeric",
                                      month: "long",
                                      day: "2-digit",
                                    }
                                  );
                                  setShowDate(e.target.value);
                                  setPickUpDate(formattedDate);
                                  setSelected({
                                    ...selected,
                                    rideData: {
                                      ...selected?.rideData,
                                      pickUpDate: formattedDate,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="w-full flex-1">
                            <label
                              htmlFor="email"
                              className="block text-sm/6 font-medium text-gray-900"
                            >
                              Pick-up time
                            </label>
                            <div className="mt-2">
                              <select
                                name="Pick Up Time"
                                id="pickup-time"
                                value={selected?.rideData?.pickUpTime}
                                style={{
                                  fontWeight: 400,
                                  lineHeight: 1.6,
                                  border: "1px solid #031b4e80",
                                  borderRadius: "3px",
                                  height: "40px",
                                  width: "100%",
                                  paddingLeft: 12,
                                  color: "#4f4f4f",
                                }}
                                onChange={(e) => {
                                  setSelected({
                                    ...selected,
                                    rideData: {
                                      ...selected?.rideData,
                                      pickUpTime: e.target.value,
                                    },
                                  });
                                }}
                              >
                                <option disabled value="Select time">
                                  Time
                                </option>
                                <option value="00:00 AM">00:00 AM</option>
                                <option value="00:30 AM">00:30 AM</option>
                                <option value="01:00 AM">01:00 AM</option>
                                <option value="01:30 AM">01:30 AM</option>
                                <option value="02:00 AM">02:00 AM</option>
                                <option value="02:30 AM">02:30 AM</option>
                                <option value="03:00 AM">03:00 AM</option>
                                <option value="03:30 AM">03:30 AM</option>
                                <option value="04:00 AM">04:00 AM</option>
                                <option value="04:30 AM">04:30 AM</option>
                                <option value="05:00 AM">05:00 AM</option>
                                <option value="05:30 AM">05:30 AM</option>
                                <option value="06:00 AM">06:00 AM</option>
                                <option value="06:30 AM">06:30 AM</option>
                                <option value="07:00 AM">07:00 AM</option>
                                <option value="07:30 AM">07:30 AM</option>
                                <option value="08:00 AM">08:00 AM</option>
                                <option value="08:30 AM">08:30 AM</option>
                                <option value="09:00 AM">09:00 AM</option>
                                <option value="09:30 AM">09:30 AM</option>
                                <option value="10:00 AM">10:00 AM</option>
                                <option value="10:30 AM">10:30 AM</option>
                                <option value="11:00 AM">11:00 AM</option>
                                <option value="11:30 AM">11:30 AM</option>
                                <option value="12:00 PM">12:00 PM</option>
                                <option value="12:30 PM">12:30 PM</option>
                                <option value="01:00 PM">01:00 PM</option>
                                <option value="01:30 PM">01:30 PM</option>
                                <option value="02:00 PM">02:00 PM</option>
                                <option value="02:30 PM">02:30 PM</option>
                                <option value="03:00 PM">03:00 PM</option>
                                <option value="03:30 PM">03:30 PM</option>
                                <option value="04:00 PM">04:00 PM</option>
                                <option value="04:30 PM">04:30 PM</option>
                                <option value="05:00 PM">05:00 PM</option>
                                <option value="05:30 PM">05:30 PM</option>
                                <option value="06:00 PM">06:00 PM</option>
                                <option value="06:30 PM">06:30 PM</option>
                                <option value="07:00 PM">07:00 PM</option>
                                <option value="07:30 PM">07:30 PM</option>
                                <option value="08:00 PM">08:00 PM</option>
                                <option value="08:30 PM">08:30 PM</option>
                                <option value="09:00 PM">09:00 PM</option>
                                <option value="09:30 PM">09:30 PM</option>
                                <option value="10:00 PM">10:00 PM</option>
                                <option value="10:30 PM">11:30 PM</option>
                                <option value="11:00 PM">11:00 PM</option>
                                <option value="11:30 PM">12:30 PM</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="mt-5 m-4 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                          <button
                            type="button"
                            className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                            onClick={() => {
                              updateRide(selected);
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="m-4 text-green-700 bg-green-50 ring-green-600/20 rounded-md px-2 py-1 text-md text-center font-medium ring-1 ring-inset">
                        {selected?.rideData?.pickUpDate} |{" "}
                        {selected?.rideData?.pickUpTime}
                      </div>
                    )}

                    <div
                      className="relative h-40 sm:h-56"
                      style={{
                        bottom: 0,
                      }}
                    >
                      <img
                        alt=""
                        src={selected?.carData?.img}
                        className="absolute size-full object-cover"
                      />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        open={openModal}
        onClose={setOpenModal}
        className="relative z-10"
        style={{
          zIndex: 99,
        }}
      >
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-xl sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div>
                  <div>
                    <div className="pb-4">
                      <h2 className="text-base/7 font-semibold text-gray-900">
                        Ride details
                      </h2>
                      <p className="mt-1 text-sm/6 text-gray-600">
                        Booking ride details
                      </p>
                      <div className="relative">
                        <div
                          aria-hidden="true"
                          className="absolute inset-0 flex items-center"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Pick-up address
                          </label>
                          <div className="mt-2">
                            <LoadScript
                              googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                              libraries={libraries}
                            >
                              <StandaloneSearchBox
                                onLoad={(ref) =>
                                  (searchBoxPickUpRef.current = ref)
                                }
                                onPlacesChanged={handlePickUpPlaceChanged}
                              >
                                <input
                                  type="text"
                                  placeholder="Pick-up address"
                                  value={pickUpAddress}
                                  onChange={(e) =>
                                    setPickUpAddress(e.target.value)
                                  }
                                  className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </StandaloneSearchBox>
                            </LoadScript>
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Drop-off address
                          </label>
                          <div className="mt-2">
                            <LoadScript
                              googleMapsApiKey="AIzaSyDCgA8dHKZISWInKu5FXRv5SYDq73qG9jI"
                              libraries={libraries}
                            >
                              <StandaloneSearchBox
                                onLoad={(ref) =>
                                  (searchBoxDropOffRef.current = ref)
                                }
                                onPlacesChanged={handleDropOffPlaceChanged}
                              >
                                <input
                                  type="text"
                                  placeholder="Drop-off address"
                                  value={dropOffAddress}
                                  onChange={(e) =>
                                    setDropOffAddress(e.target.value)
                                  }
                                  className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                />
                              </StandaloneSearchBox>
                            </LoadScript>
                          </div>
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Pick-up date
                          </label>
                          <div className="mt-2">
                            <input
                              type="date"
                              name="Pick Up Date"
                              value={showDate}
                              className="pl-2 h-9 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              ref={dateInputRef}
                              onClick={handleClick}
                              onChange={(e) => {
                                const [year, month, day] =
                                  e.target.value.split("-");
                                const date = new Date(year, month - 1, day);
                                const formattedDate = date.toLocaleDateString(
                                  "en-US",
                                  {
                                    year: "numeric",
                                    month: "long",
                                    day: "2-digit",
                                  }
                                );
                                setShowDate(e.target.value);
                                setPickUpDate(formattedDate);
                                setNewRide({
                                  ...newRide,
                                  rideData: {
                                    ...newRide?.rideData,
                                    pickUpDate: formattedDate,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Pick-up time
                          </label>
                          <div className="mt-2">
                            <select
                              name="Pick Up Time"
                              id="pickup-time"
                              value={newRide?.rideData?.pickUpTime}
                              className="pl-2 h-9 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              onChange={(e) => {
                                setNewRide({
                                  ...newRide,
                                  rideData: {
                                    ...newRide?.rideData,
                                    pickUpTime: e.target.value,
                                  },
                                });
                              }}
                            >
                              <option disabled value="Select time">
                                Time
                              </option>
                              <option value="00:00 AM">00:00 AM</option>
                              <option value="00:30 AM">00:30 AM</option>
                              <option value="01:00 AM">01:00 AM</option>
                              <option value="01:30 AM">01:30 AM</option>
                              <option value="02:00 AM">02:00 AM</option>
                              <option value="02:30 AM">02:30 AM</option>
                              <option value="03:00 AM">03:00 AM</option>
                              <option value="03:30 AM">03:30 AM</option>
                              <option value="04:00 AM">04:00 AM</option>
                              <option value="04:30 AM">04:30 AM</option>
                              <option value="05:00 AM">05:00 AM</option>
                              <option value="05:30 AM">05:30 AM</option>
                              <option value="06:00 AM">06:00 AM</option>
                              <option value="06:30 AM">06:30 AM</option>
                              <option value="07:00 AM">07:00 AM</option>
                              <option value="07:30 AM">07:30 AM</option>
                              <option value="08:00 AM">08:00 AM</option>
                              <option value="08:30 AM">08:30 AM</option>
                              <option value="09:00 AM">09:00 AM</option>
                              <option value="09:30 AM">09:30 AM</option>
                              <option value="10:00 AM">10:00 AM</option>
                              <option value="10:30 AM">10:30 AM</option>
                              <option value="11:00 AM">11:00 AM</option>
                              <option value="11:30 AM">11:30 AM</option>
                              <option value="12:00 PM">12:00 PM</option>
                              <option value="12:30 PM">12:30 PM</option>
                              <option value="01:00 PM">01:00 PM</option>
                              <option value="01:30 PM">01:30 PM</option>
                              <option value="02:00 PM">02:00 PM</option>
                              <option value="02:30 PM">02:30 PM</option>
                              <option value="03:00 PM">03:00 PM</option>
                              <option value="03:30 PM">03:30 PM</option>
                              <option value="04:00 PM">04:00 PM</option>
                              <option value="04:30 PM">04:30 PM</option>
                              <option value="05:00 PM">05:00 PM</option>
                              <option value="05:30 PM">05:30 PM</option>
                              <option value="06:00 PM">06:00 PM</option>
                              <option value="06:30 PM">06:30 PM</option>
                              <option value="07:00 PM">07:00 PM</option>
                              <option value="07:30 PM">07:30 PM</option>
                              <option value="08:00 PM">08:00 PM</option>
                              <option value="08:30 PM">08:30 PM</option>
                              <option value="09:00 PM">09:00 PM</option>
                              <option value="09:30 PM">09:30 PM</option>
                              <option value="10:00 PM">10:00 PM</option>
                              <option value="10:30 PM">11:30 PM</option>
                              <option value="11:00 PM">11:00 PM</option>
                              <option value="11:30 PM">12:30 PM</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <h2 className="mt-4 text-base/7 font-semibold text-gray-900">
                        User/Car details
                      </h2>
                      <p className="mt-1 text-sm/6 text-gray-600">
                        User & Car information
                      </p>
                      <div className="relative">
                        <div
                          aria-hidden="true"
                          className="absolute inset-0 flex items-center"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Name
                          </label>
                          <div className="mt-2">
                            <input
                              id="name"
                              name="name"
                              type="text"
                              value={newRide?.userDetails?.name}
                              placeholder="User name"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  userDetails: {
                                    ...newRide?.userDetails,
                                    name: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Email
                          </label>
                          <div className="mt-2">
                            <input
                              id="email"
                              name="email"
                              type="email"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  userDetails: {
                                    ...newRide?.userDetails,
                                    email: e?.target?.value,
                                  },
                                }));
                              }}
                              placeholder="you@example.com"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Phone
                          </label>
                          <div className="mt-2">
                            <input
                              id="phone"
                              name="phone"
                              type="text"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  userDetails: {
                                    ...newRide?.userDetails,
                                    phone: e?.target?.value,
                                  },
                                }));
                              }}
                              placeholder="Phone contact"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="last-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Car
                          </label>
                          <div className="mt-2">
                            <select
                              id="car"
                              name="car"
                              value={newRide?.carData?.vehId}
                              onChange={(e) => {
                                var carFilterData =
                                  dashboardData?.vehiclesFleet?.data?.filter(
                                    (item) =>
                                      Number(item?.vehId) ===
                                      Number(e?.target?.value)
                                  );
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  carData: carFilterData[0],
                                }));
                              }}
                              placeholder="Phone contact"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            >
                              {dashboardData?.vehiclesFleet?.data?.map(
                                (veh, i) => {
                                  return (
                                    <option key={i} value={veh?.vehId}>
                                      {veh?.name}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                        </div>
                      </div>

                      <h2 className="mt-4 text-base/7 font-semibold text-gray-900">
                        Additional details
                      </h2>
                      <p className="mt-1 text-sm/6 text-gray-600">
                        Adding additional details
                      </p>
                      <div className="relative">
                        <div
                          aria-hidden="true"
                          className="absolute inset-0 flex items-center"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            No. of Passenger
                          </label>
                          <div className="mt-2">
                            <input
                              id="numberOfPassengers"
                              name="numberOfPassengers"
                              type="text"
                              value={
                                newRide?.additionalDetails?.numberOfPassengers
                              }
                              placeholder="0"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalDetails: {
                                    ...newRide?.additionalDetails,
                                    numberOfPassengers: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            No. of Baggage
                          </label>
                          <div className="mt-2">
                            <input
                              id="numberOfBaggage"
                              name="numberOfBaggage"
                              type="text"
                              value={
                                newRide?.additionalDetails?.numberOfBaggage
                              }
                              placeholder="0"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalDetails: {
                                    ...newRide?.additionalDetails,
                                    numberOfBaggage: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Flight number
                          </label>
                          <div className="mt-2">
                            <input
                              id="flightNumber"
                              name="flightNumber"
                              type="text"
                              value={newRide?.additionalDetails?.flightNumber}
                              placeholder="AC123"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalDetails: {
                                    ...newRide?.additionalDetails,
                                    flightNumber: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            Additional Details
                          </label>
                          <div className="mt-2">
                            <textarea
                              id="additionalDetailsText"
                              name="additionalDetailsText"
                              rows={2}
                              placeholder="Additional details"
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                              value={
                                newRide?.additionalDetails
                                  ?.additionalDetailsText
                              }
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalDetails: {
                                    ...newRide?.additionalDetails,
                                    additionalDetailsText: e?.target?.value,
                                  },
                                }));
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <h2 className="mt-4 text-base/7 font-semibold text-gray-900">
                        Additional seat details
                      </h2>
                      <p className="mt-1 text-sm/6 text-gray-600">
                        Adding additional seat details
                      </p>
                      <div className="relative">
                        <div
                          aria-hidden="true"
                          className="absolute inset-0 flex items-center"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            No. of Booster Seats needed (4-5 Years Old)
                          </label>
                          <div className="mt-2">
                            <input
                              id="boosterSeat"
                              name="boosterSeat"
                              type="text"
                              value={
                                newRide?.additionalSeatsDetails?.boosterSeat
                              }
                              placeholder="0"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalSeatsDetails: {
                                    ...newRide?.additionalSeatsDetails,
                                    boosterSeat: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                        <div className="sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            No. of Child Seats needed (1-3 Years Old)
                          </label>
                          <div className="mt-2">
                            <input
                              id="childSeat"
                              name="childSeat"
                              type="text"
                              value={newRide?.additionalSeatsDetails?.childSeat}
                              placeholder="0"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalSeatsDetails: {
                                    ...newRide?.additionalSeatsDetails,
                                    childSeat: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-6">
                          <label
                            htmlFor="first-name"
                            className="block text-sm/6 font-medium text-gray-900"
                          >
                            No. of Infant Seats needed (16 months or younger)
                          </label>
                          <div className="mt-2">
                            <input
                              id="infantSeat"
                              name="infantSeat"
                              type="text"
                              value={
                                newRide?.additionalSeatsDetails?.infantSeat
                              }
                              placeholder="0"
                              onChange={(e) => {
                                setNewRide((prevData) => ({
                                  ...prevData,
                                  additionalSeatsDetails: {
                                    ...newRide?.additionalSeatsDetails,
                                    infantSeat: e?.target?.value,
                                  },
                                }));
                              }}
                              className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setLoading(true);
                    newRide.rideData.dropOffAddress = dropOffAddress;
                    newRide.rideData.pickUpAddress = pickUpAddress;
                    newRide.rideData.pickUpDate = pickUpDate;

                    var config = {
                      method: "post",
                      url: `${baseUrl}/v1/admin/createRide`,
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: userDetails?.authToken,
                      },
                      data: newRide,
                    };
                    axios
                      .request(config)
                      .then((response) => {
                        setLoading(false);
                        if (response?.data?.success) {
                          setOpenModal(false);
                          toast.success("Success!! Ride created.");
                          setTimeout(() => {
                            window.location.reload();
                          }, 1000);
                        }
                      })
                      .catch((error) => {
                        setLoading(false);
                        toast.warn("Error!! Please try again later.");
                      });
                  }}
                  className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Create Ride
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>

      <div className="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto px-6 p-24">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold text-gray-900">Rides</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the booked rides.
            </p>
          </div>
          {userDetails?.role === "ADMIN" ||
          userDetails?.role === "DISPATCHER" ? (
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <button
                type="button"
                className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Add Ride
              </button>
            </div>
          ) : null}
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Id
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Itinerary
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        User Details
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Driver
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                      >
                        <span className="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {dashboardData?.allRides?.data?.map((person, i) => (
                      <tr key={person?.createdDate}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          <div className="flex-auto">
                            <div className="flex items-start gap-x-3">
                              <div className="text-sm/6 font-medium text-gray-900">
                                {person?.orderNumber}
                              </div>
                            </div>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              {moment
                                .unix(person?.createdDate)
                                .format("MMM DD,YYYY")}
                            </div>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              {person?.carData?.name}
                            </div>
                            <div className="mt-1 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-700/10">
                              {person?.rideData?.rideType?.title === "Hourly"
                                ? "Hourly"
                                : "Point to point"}
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex gap-x-2">
                            <div>
                              <ArrowDownCircleIcon
                                aria-hidden="true"
                                className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                              />
                              <InformationCircleIcon
                                aria-hidden="true"
                                className="hidden h-6 w-5 flex-none text-gray-400 sm:block"
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    person?.additionalDetails
                                      ?.additionalDetailsText != ""
                                  ) {
                                    toast.info(
                                      person?.additionalDetails
                                        ?.additionalDetailsText
                                    );
                                  } else {
                                    toast.warn("No Additional Details");
                                  }
                                }}
                              />
                            </div>

                            <div className="flex-auto">
                              <div className="flex items-start gap-x-3">
                                <div className="text-sm/6 font-medium text-gray-900">
                                  {person?.rideData?.pickUpAddress}
                                </div>
                              </div>
                              {person?.rideData?.rideType?.title ===
                              "Hourly" ? (
                                <div className="mt-1 text-xs/5 text-gray-500">
                                  Booking Hours:&nbsp;
                                  {person?.rideData?.rideHours}
                                </div>
                              ) : (
                                <div className="mt-1 text-xs/5 text-gray-500">
                                  {person?.rideData?.dropOffAddress}
                                </div>
                              )}

                              <div className="mt-1 text-green-700 bg-green-50 ring-green-600/20 rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset">
                                {person?.rideData?.pickUpDate} |{" "}
                                {person?.rideData?.pickUpTime}
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  border: "1px solid #ccc",
                                  borderRadius: 4,
                                  paddingLeft: 4,
                                  paddingRight: 4,
                                  marginTop: 6,
                                  borderBottomLeftRadius: 0,
                                  borderBottomRightRadius: 0,
                                }}
                              >
                                <div className="text-xs/5 text-gray-500 flex items-center">
                                  No. of passenger:{" "}
                                  <span className="font-bold text-lg">
                                    {
                                      person?.additionalDetails
                                        ?.numberOfPassengers
                                    }
                                  </span>
                                </div>
                                <div className="text-xs/5 text-gray-500 flex items-center">
                                  No. of baggage:{" "}
                                  <span className="font-bold text-lg">
                                    {person?.additionalDetails?.numberOfBaggage}
                                  </span>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  border: "1px solid #ccc",
                                  borderTop: 0,
                                  borderRadius: 4,
                                  paddingLeft: 4,
                                  paddingRight: 4,
                                  borderTopLeftRadius: 0,
                                  borderTopRightRadius: 0,
                                }}
                              >
                                <div className="text-xs/5 text-gray-500 flex items-center">
                                  Booster Seat:{" "}
                                  <span className="font-bold text-lg">
                                    {
                                      person?.additionalSeatsDetails
                                        ?.boosterSeat
                                    }
                                  </span>
                                </div>
                                <div className="text-xs/5 text-gray-500 flex items-center">
                                  Child Seat:{" "}
                                  <span className="font-bold text-lg">
                                    {person?.additionalSeatsDetails?.childSeat}
                                  </span>
                                </div>
                                <div className="text-xs/5 text-gray-500 flex items-center">
                                  Infant Seat:{" "}
                                  <span className="font-bold text-lg">
                                    {person?.additionalSeatsDetails?.infantSeat}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex-auto">
                            <div className="flex items-start gap-x-3">
                              <div className="text-sm/6 font-medium text-gray-900">
                                {person?.userDetails?.name}
                              </div>
                            </div>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              {person?.userDetails?.email}
                            </div>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              {person?.userDetails?.phone}
                            </div>
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex-auto">
                            <div className="flex items-start gap-x-3">
                              <div className="text-sm/6 font-medium text-gray-900">
                                <h6 className="m-0">
                                  {person?.status?.toUpperCase()}
                                </h6>
                                {userDetails?.role === "ADMIN" ||
                                userDetails?.role === "DISPATCHER" ? (
                                  <select
                                    id="status"
                                    name="status"
                                    value={person?.status}
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                    onChange={(e) => {
                                      var updatedItems =
                                        dashboardData?.allRides?.data?.map(
                                          (item) => {
                                            if (
                                              item.orderNumber ===
                                              person?.orderNumber
                                            ) {
                                              return {
                                                ...item,
                                                status: e.target.value,
                                              };
                                            }
                                            return item;
                                          }
                                        );
                                      setDashboardData({
                                        ...dashboardData,
                                        allRides: {
                                          ...dashboardData?.allRides,
                                          data: updatedItems,
                                        },
                                      });
                                      var updateUserData = updatedItems?.filter(
                                        (item) =>
                                          item?.orderNumber ===
                                          person?.orderNumber
                                      );
                                      updateRide(updateUserData[0]);
                                    }}
                                  >
                                    <option value="scheduled">Scheduled</option>
                                    <option value="confirmed">Confirmed</option>
                                    <option value="completed">Completed</option>
                                    <option value="cancelled">Cancelled</option>
                                  </select>
                                ) : (
                                  person?.status?.toUpperCase()
                                )}
                              </div>
                            </div>
                            <h6 className="mt-2">{person?.subStatus}</h6>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              <select
                                id="subStatus"
                                name="subStatus"
                                value={person?.subStatus}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                onChange={(e) => {
                                  var updatedItems =
                                    dashboardData?.allRides?.data?.map(
                                      (item) => {
                                        if (
                                          item.orderNumber ===
                                          person?.orderNumber
                                        ) {
                                          return {
                                            ...item,
                                            subStatus: e.target.value,
                                          };
                                        }
                                        return item;
                                      }
                                    );
                                  setDashboardData({
                                    ...dashboardData,
                                    allRides: {
                                      ...dashboardData?.allRides,
                                      data: updatedItems,
                                    },
                                  });
                                  var updateUserData = updatedItems?.filter(
                                    (item) =>
                                      item?.orderNumber === person?.orderNumber
                                  );
                                  updateRide(updateUserData[0]);
                                }}
                              >
                                <option value="">No Status</option>
                                <option value="On the way">On the way</option>
                                <option value="On location">On location</option>
                                <option value="Passenger on board">
                                  Passenger on board
                                </option>
                                <option value="Drop off/Done">
                                  Drop off/Done
                                </option>
                                <option value="Late Cancel">Late Cancel</option>
                                <option value="No Show">No Show</option>
                              </select>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <div className="flex-auto">
                            <div className="flex items-start gap-x-3">
                              <div className="text-sm/6 font-medium text-gray-900">
                                {person?.assignedDriver?.name}&nbsp;
                                {person?.assignedDriver?.vehicles
                                  ? `| ${person?.assignedDriver?.vehicles}`
                                  : null}
                              </div>
                            </div>
                            <div className="mt-1 text-xs/5 text-gray-500">
                              {person?.assignedDriver?.email}
                            </div>
                            {userDetails?.role === "ADMIN" ||
                            userDetails?.role === "DISPATCHER" ? (
                              <div className="mt-1 text-xs/5 text-gray-500">
                                <select
                                  id="driver"
                                  name="driver"
                                  value={person?.assignedDriver?.driverId}
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
                                  onChange={(e) => {
                                    var driverData =
                                      dashboardData?.registeredDrivers?.data?.filter(
                                        (d) =>
                                          Number(d?.driverId) ===
                                          Number(e?.target?.value)
                                      );

                                    var updatedItems =
                                      dashboardData?.allRides?.data?.map(
                                        (item) => {
                                          if (
                                            item.orderNumber ===
                                            person?.orderNumber
                                          ) {
                                            return {
                                              ...item,
                                              assignedDriver: driverData[0],
                                            }; // Update the name
                                          }
                                          return item;
                                        }
                                      );
                                    setDashboardData({
                                      ...dashboardData,
                                      allRides: {
                                        ...dashboardData?.allRides,
                                        data: updatedItems,
                                      },
                                    });

                                    var updateUserData = updatedItems?.filter(
                                      (item) =>
                                        item?.orderNumber ===
                                        person?.orderNumber
                                    );
                                    if (e?.target?.value !== "") {
                                      updateRide(updateUserData[0]);
                                    }
                                  }}
                                >
                                  <option value="">Assign driver</option>
                                  {dashboardData?.registeredDrivers?.data?.map(
                                    (driver, i) => {
                                      return (
                                        <option
                                          value={driver?.driverId}
                                          key={i}
                                        >
                                          {driver?.name} | {driver?.email} |{" "}
                                          {driver?.vehicles}
                                        </option>
                                      );
                                    }
                                  )}
                                </select>
                              </div>
                            ) : null}
                          </div>
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          <div
                            className="text-indigo-600 hover:text-indigo-900"
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const savedDate = new Date(
                                person?.rideData?.pickUpDate
                              );
                              setShowDate(
                                savedDate.toISOString().split("T")[0]
                              );
                              setSelected(person);
                              setOpen(true);
                            }}
                          >
                            View
                          </div>
                          {userDetails?.role === "ADMIN" ? (
                            <div
                              className="text-red-600 hover:text-red-900"
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                generateInvoice(person);
                              }}
                            >
                              Invoice
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Rides;
